import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import PrivacyPolicy from '../components/CookiePolicy/privacyPolicy'
import { Helmet } from "react-helmet";

// const content = `DueFocus aims to protect your confidentiality and provide you a comfortable experience of working with our website, products, and services.`
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const Privacy = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>Privacy Policy - duefocus</title>
      <meta name="description" content={content} />
      <meta name="robots" content="noindex, nofollow" />
      <link rel="canonical" href="https://www.duefocus.com/privacy-policy/" />
    </Helmet>
    <Header />
    <PrivacyPolicy />
    <Footer />
  </>
)

export default Privacy