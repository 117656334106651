import React from 'react'
import styles from './cookiePolicy.module.scss'
import EmptyBlock from '../Header/emptyBlock'


export default class PrivacyPolicy extends React.Component {
  //   handleCloseFeatures = () => {
  //     // document.getElementById('drop-down').classList.remove('drop-down-active');
  //     document.getElementById('drop-down-pricing').classList.remove('drop-down-active');
  //     document.getElementById('overlay').removeAttribute('class');
  //     document.body.removeAttribute('class');
  // }
  render() {
    return (
      <>
        <EmptyBlock />
        {/* <div onMouseEnter={this.handleCloseFeatures} id='overlay'></div> */}
        <div className={styles.main}>
          <div className={styles.content}>
            <h1>Privacy Policy</h1>
            <h2>DueFocus acknowledges the essentiality of information privacy for its users.</h2>
            <p>DueFocus is firmly committed to protecting the privacy of Internet users and software users, fostering users’ confidence in the Internet, computer software, and computer applications, and has created this privacy policy statement in order to show our commitment to protecting your privacy. By using the DueFocus or its services users also consent to this Privacy Policy.</p>
            <p>DueFocus aims to protect your confidentiality and provide you a comfortable experience of working with our website, products, and services.</p>
            <p>This Privacy Policy explains who we are, why and how we collect and work with personalized data, and the rights you have as a User when you provide us with any personal information, as well as how you can get in touch with us should you need to do so.</p>
            <p>We reserve the right to update and modify this Privacy Policy at any time and for any reason.</p>
            <p>The date of the latest Privacy Policy update can be seen in the “Last Updated” line at the top of this page.</p>
          </div>
          <div className={styles.content}>
            <h2>Who We Are: </h2>
            <p>We are DueFocus. Our contact information and address are provided at the bottom of this Privacy Policy. We are the end-controllers of your personal data and process it according to the conditions stipulated in this Policy.</p>
            <p>Additional information about the processing of your personal data can be introduced in offer descriptions, additional statements on confidentiality, or in notifications shown at times personal data is being collected.</p>
            <p>Some DueFocus websites might have their own Privacy Policy with a description of the procedure with which your personal information is processed on these websites.</p>
            <p>If a notification is displayed during the collection of data, or a confidentiality statement of a specific website or solution contradicts this Privacy Policy, the statement or notification will prevail over this Policy.</p>
          </div>
          <div className={styles.content}>
            <h2>Whose Personal Data Do We Process? </h2>
            <p>We can process your personal data if:</p>
            <ul>
              <li>You are a client or vendor of DueFocus (or are interested in becoming one);</li>
              <li>You use products and/or services that have been developed by us;</li>
              <li>You work for our client and/or vendor, or someone who uses services and/or products that have been developed by us;</li>
              <li>You are a client (or work for a client), who we want to tell about and offer our services and/or products;</li>
              <li>You are our employee, subcontractor, or are interested in working with us.</li>
            </ul>
          </div>
          <div className={styles.content}>
            <h2>Whose Data Do We Not Process? </h2>
            <p>We deliberately do not request or collect personal data of children under 13 years of age and do not offer them our services. If we discover that a person under the age of 13 has given us their personal data without parental approval, we will take all necessary measures to delete this information.</p>
            <p>DueFocus recommends parents and guardians to oversee the actions of children on the Internet. If you believe that we may have been given personal information from a person who hasn’t yet reached the age of consent in your jurisdiction without the necessary approval, please tell us, and we will take all necessary measures</p>
          </div>
          <div className={styles.content}>
            <h2>What Kind Of Personal Information Do We Collect? </h2>
            <p>We can process personal information that has been provided directly by you or by a third party.</p>
            <p>This information can include:</p>
            <ul>
              <li>Your name;</li>
              <li>Information about your employer, subdivision, and official duties;</li>
              <li>Your address, phone number, email address, or any other contact details (personal and/or work, depending on the type of relationship we have with you or the entity which employs you);</li>
              <li>Personal information given to us during phone conversations, email correspondence, the use of our website (information gathered when you register to receive newsletters; place an order; participate in a contest, advertising campaign, or survey; form submits when you are reporting a problem), or during a time when you are making actions for your employer;</li>
              <li>Information regarding any cooperation between DueFocus and you or your employer (such as requesting specifications for products we’ve developed and/or services we have provided to you or your employer);</li>
              <li>Other personal information that is necessary to draft and/or execute an agreement and/or contract with you or your employer (such as the right to process your commercial information, information received from credit history agencies, or any other information stipulated in an agreement between DueFocus and you, your employer, or any other third party to which you are somehow connected and/or related);</li>
              <li>Information about events to which you or any party connected and/or related to you have been invited, as well as your personal information and preferences in the case when these are required for organizing and overseeing these events (such as your preferences regarding catering);</li>
              <li>Any personal information you provide us with or which we receive during your visits (such as when you are registered on our video survailance systems or register at the reception when visiting any of the DueFocus offices, or if you provide us with registration information of your vehicle);</li>
              <li>Personal information which you provide us with while visiting our website, including:</li>
            </ul>
            <div className={styles.ul}>
              <ul>
                <li>Technical information including the IP address which you use to connect to the Internet;</li>
                <li>Your registration information;</li>
                <li>The type and version of your browser;</li>
                <li>Your time-zone;</li>
                <li>Versions and information about your browser plugins;</li>
                <li>Your operating system and platform;</li>
                <li>Information about your visit, including visited URLs;</li>
                <li>Your browsing history across our website;</li>
                <li>Information about the time and date when you visited our website;</li>
                <li>Services and pages that you saw and searched for;</li>
                <li>Time spent on each page;</li>
                <li>Page response time;</li>
                <li>Information on your activities on any page of our website (such as scrolling and mouse clicks or hovers);</li>
                <li>Ways in which you landed on pages as well as the phone number or social buttons you used to get in touch with us.</li>
              </ul>
            </div>
            <p>If you provide DueFocus with data of/from third parties (such as their names, email addresses, phone numbers, etc.), you guarantee that you have the rights to do so. This includes, but is not limited to, forwarding of referential or marketing materials to your friends or sharing information on open vacancies.</p>
            <p>Third parties can opt-out of future messages by clicking the corresponding link in the original message or by getting in touch with us at <span>privacy@diligences.com</span>.</p>
            <p>In certain cases, DueFocus and partner third-parties can automatically collect data using browser cookies, web logs, web beacons, and other similar applications and attachments.</p>
            <p>This information is used for analysis, improving convenience and efficiency of our website, and to help us provide you with relevant and interesting materials and offers.</p>
            <p>Additional information about this is available in our Cookie Policy.</p>
          </div>
          <div className={styles.content}>
            <h2>Cookie Files</h2>
            <p>The Terms of DueFocus’s use of cookie files to collect and process personal information are provided in our Cookie Policy. Please read it.</p>
          </div>
          <div className={styles.content}>
            <h2>What We Do With Your Personal Information</h2>
            <p>We process your personal information for the following reasons:</p>
            <ul>
              <li>Advertising and sales activities as well as presenting information about our services and products (in ways that we determine ourselves);</li>
              <li>Creating and executing contracts and agreements with you or your employer to:</li>
            </ul>
            <div className={styles.ul}>
              <ul>
                <li>Provide services to clients and other users of these services;</li>
                <li>Receive goods and services from vendors;</li>
                <li>Control our relationship with clients, vendors, and other parties who use our services or products, wherein you or your employer can be the client, user, or vendor of goods and services for the aforementioned goals;</li>
              </ul>
            </div>
            <ul>
              <li>Administering our website in accordance with the conditions stipulated in the agreement and for internal processing, including fixes of defects, data analysis, testing, investigating, and collecting statistics and surveys;</li>
              <li>Improving our website to provide content in the most effective way for you and the device you are using;</li>
              <li>Improving safety during your visit of our website and use of our products and services;</li>
              <li>Evaluation and understanding of the efficiency of the advertising that we display to you and other parties as well as displaying relevant advertising;</li>
              <li>Providing tips and recommendations on services which could be interesting to you or your employer in accordance with your preferences (where applicable).</li>
            </ul>
          </div>
          <div className={styles.content}>
            <h2>To Whom Do We Disclose Your personal Information.</h2>
            <p>We can share your personal data with:</p>
            <ul>
              <li>Any member of our company, including subsidiaries, affiliated companies, individual business units and their subsidiaries, affiliated companies, and business units.</li>
              <li>Certain third parties, including:</li>
            </ul>
            <div className={styles.ul}>
              <ul>
                <li>Our business partners, clients, vendors, and subcontractors to execute any agreements and contracts which we draw, or other deal and/or transaction that takes place during our work with you or your employer;</li>
                <li>Our auditors, legal consultants, and other professional consultants or</li>
              </ul>
            </div>
            <ul>
              <li>We can share data collected via our website with:</li>
            </ul>
            <div className={styles.ul}>
              <ul>
                <li>Our advertisers and advertising networks which need this data to select and display relevant ads to you and others. We do not disclose any data that can personally identify you to advertisers, but we do provide general data about our users. We can also use this general data to help advertisers display ads to our target audience. We can use your personal data to demonstrate ads of our advertisers and their target audience in accordance with the article about using Cookies displayed in this Policy</li>
                <li>Providers of analytics and search engines which help us improve and optimize our website in accordance with the article about using Cookies displayed in this Policy.</li>
              </ul>
            </div>
          </div>
          <div className={styles.content}>
            <h2>Other Cases Of Revealing Personal Data </h2>
            <p>We can disclose your personal information to third parties:</p>
            <ul>
              <li>In the case of selling or purchasing any business or assets (including, but not limited to, our business and assets). In this case, we can disclose your personal information to a potential seller or buyer of said business or assets in accordance with the conditions of this Privacy Policy. If our business and all our assets will be purchased by a third party, all personal data that they receive about clients will be part of the transferred assets;</li>
              <li>With the goal of completing any lawful obligations or for completing or abidance of any contract conditions, conditions of service provisions, or other agreements made with you in cases when we are required to disclose or share your personal data to achieve the goals stipulated in these agreements;</li>
              <li>To protect the rights, property, and security of DueFocus, our clients, our employees, and other users. This includes sharing information with other companies and organizations (including law enforcement) with the goal of preventing fraud and other risks.</li>
            </ul>
          </div>
          <div className={styles.content}>
            <h2>Use Of Social Plugins As Part Of Social Media </h2>
            <br />
            <h3>Facebook</h3>
            <p>DueFocus websites use social plugins of Facebook Inc., 1601 Willow Road, Menlo Park, CA 94025 ( “Facebook”).</p>
            <p>Plugins are identified by the Facebook logo with a “Facebook”, “Like”, or “Share” prefix.</p>
            <p>When you visit our website these plugins are turned off by default. Plugins remain turned off until you click on the corresponding button. When these plugins are turned on, you are connected to Facebook and give Facebook your permission to transfer data to Facebook. If you are registered to Facebook, Facebook can link your current website visit with your Facebook profile.</p>
            <p>When you click one of these buttons, this information is transferred by your browser to Facebook and remains stored there. To receive information regarding the goal and scope of collected information, its further processing, your rights and settings you can use to protect your privacy, please read the Privacy Policy on the official website of Facebook (<a href='https://www.facebook.com/'>www.facebook.com</a>).</p>
            <p>If you would like to opt-out of Facebook’s collection of your data when visiting our website, you must log out of your Facebook profile before visiting our website.</p>
            <br />
            <h3>Google+</h3>
            <p>DueFocus websites use social plugins of Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
            <p>Plugins are identified by the Google+ logo with a “Google+”, “Google Plus”, or “+1” prefix.</p>
            <p>When you visit our website these plugins are turned off by default. Plugins remain turned off until you click on the corresponding button. When these plugins are turned on, you are connected to Google and give Google your permission to transfer data to Google. If you are registered to Google+, Google can link your current website visit with your Google+ profile.</p>
            <p>When you click one of these buttons, this information is transferred by your browser to Google+ and remains stored there. To receive information regarding the goal and scope of collected information, its further processing, your rights and settings you can use to protect your privacy, please read the Privacy Policy on the official website of Google (<a href='https://www.google.com/'>www.google.com</a>).</p>
            <p>If you would like to opt-out of Google+’s collection of your data when visiting our website, you must log out of your Google+ profile before visiting our website.</p>
            <br />
            <h3>Linkedin</h3>
            <p>DueFocus websites use social plugins of LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085, USA.</p>
            <p>Plugins are identified by the LinkedIn logo.</p>
            <p>When you visit our website these plugins are turned off by default. Plugins remain turned off until you click on the corresponding button. When these plugins are turned on, you are connected to LinkedIn and give LinkedIn your permission to transfer data to LinkedIn. If you are registered to LinkedIn, LinkedIn can link your current website visit with your LinkedIn profile.</p>
            <p>When you click one of these buttons, this information is transferred by your browser to LinkedIn and remains stored there. To receive information regarding the goal and scope of collected information, its further processing, your rights and settings you can use to protect your privacy, please read the Privacy Policy on the official website of LinkedIn (<a href='https://www.linkedin.com/'>https://www.linkedin.com</a>).</p>
            <p>If you would like to opt-out of LinkedIn’s collection of your data when visiting our website, you must log out of your LinkedIn profile before visiting our website.</p>
            <br />
            <h3>Pinterest</h3>
            <p>DueFocus websites use social plugins of Pinterest Inc., 651 Brannan Street, San Francisco, CA 94103.</p>
            <p>Plugins are identified by the Pinterest logo.</p>
            <p>When you visit our website these plugins are turned off by default. Plugins remain turned off until you click on the corresponding button. When these plugins are turned on, you are connected to Pinterest and give Pinterest your permission to transfer data to Pinterest. If you are registered to Pinterest, Pinterest can link your current website visit with your Pinterest profile.</p>
            <p>When you click one of these buttons, this information is transferred by your browser to Pinterest and remains stored there. To receive information regarding the goal and scope of collected information, its further processing, your rights and settings you can use to protect your privacy, please read the Privacy Policy on the official website of Pinterest (<a href="https://www.pinterest.ru/">www.pinterest.com</a>).</p>
            <p>If you would like to opt-out of Pinterest’s collection of your data when visiting our website, you must log out of your Pinterest profile before visiting our website.</p>
            <br />
            <h3>Twitter</h3>
            <p>DueFocus websites use social plugins of Twitter, which is owned by Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103 («Twitter»).</p>
            <p>Plugins are identified by the Twitter logo with a “Tweet”, or “Share” prefix.</p>
            <p>When you visit our website these plugins are turned off by default. Plugins remain turned off until you click on the corresponding button. When these plugins are turned on, you are connected to Twitter and give Twitter your permission to transfer data to Twitter. If you are registered to Twitter, Twitter can link your current website visit with your Twitter profile.</p>
            <p>When you click one of these buttons, this information is transferred by your browser to Twitter and remains stored there. To receive information regarding the goal and scope of collected information, its further processing, your rights and settings you can use to protect your privacy, please read the Privacy Policy on the official website of Twitter (<a href='https://twitter.com/'>www.twitter.com</a>).</p>
            <p>If you would like to opt-out of Twitter’s collection of your data when visiting our website, you must log out of your Twitter profile before visiting our website.</p>
          </div>
          <div className={styles.content}>
            <h2>Usage Of Outbound Links</h2>
            <p>DueFocus websites might include links that lead to other websites, websites that are controlled by parties we are not related or connected to. After clicking an outbound link we lose any control of collection, storage, or processing of your personal data that is transferred</p>
            <p>The behavior of third-parties is also beyond the scope of our control, therefore DueFocus is not responsible for the collection, storage, and processing of information you share with third-parties.</p>
          </div>
          <div className={styles.content}>
            <h2>Forums And Topical Chats</h2>
            <p>If you are participating in a forum discussion, local communities, or thematic chats on DueFocus websites, please remember that your information (your profile) will be widely accessible to other users and can be used to get in touch with you, sending you unwanted messages, or to accomplish goals that are beyond the control of DueFocus. Furthermore, be aware that some forums and chats may have additional usage Terms and Conditions. DueFocus is not responsible for the information (personal or otherwise) that is shared on these forums and in these chats. To request the deletion of your personal information from our blog or forum community, please get in touch with us at <span>privacy@diligences.com</span></p>
            <p>In some cases we will not be able to remove your personal information. In cases like these, we will tell you about this and give you reasons why this is the case.</p>
          </div>
          <div className={styles.content}>
            <h2>What Is The Legal Basis Of The Processing Of Your Personal Data?</h2>
            <p>When processing your personal information we draw upon:</p>
            <ul>
              <li>Your explicit agreement to the processing of your data in specific purposes (like direct marketing). Along with this, we get your permission to process your data (please refer to sections below which explain how you can opt-out of this processing).</li>
              <li>Without your agreement we will only process your data in cases when it is necessary to:</li>
            </ul>
            <div className={styles.ul}>
              <ul>
                <li>Execute an agreement between you (or your employer) and to take the necessary steps for drawing out this agreement;</li>
                <li>To abide to legal requirements that we have taken upon ourselves;</li>
                <li>In legal interests pursued by us or another party under the condition that these interests are not against your interests or the laws and regulations that demand the protection of your personal data (most conditions under which we process your personal data fall under the relationship characteristics between DueFocus and your employer will fall under this category).</li>
              </ul>
            </div>
          </div>
          <div className={styles.content}>
            <h2>How Long We Process Your Personal Data</h2>
            <p>We process personal data as long as it takes to achieve the goal (goals) for which this data was originally collected. After this your data will be deleted or transferred to an archive, except for cases when it is necessary to continue the processing of your data to abide by our legal obligations that we have or other rightful and legal purposes.</p>
          </div>
          <div className={styles.content}>
            <h2>How Long We Process Your Personal Data</h2>
            <p>The personal data that is processed by DueFocus may be transferred and stored outside of the European Economic Area (EEA), this data can not be subjected to other laws of data protection.</p>
            <p>This data can also be processed by employees outside of the EEA, who are employed by DueFocus or one of our vendors. This category includes employees that, amongst other things, process orders, process payment requisites, and provide support services.</p>
            <p>If we receive personal data while we are providing services, we will take all necessary measures to guarantee:</p>
            <ul>
              <li>Abidance to all necessary security measures that pervade over the data;</li>
              <li>That your data is secure in accordance with this Privacy Policy.</li>
            </ul>
            <p>We adhere to all commonly accepted industry standards for the protection of personal information that has been shared with us during the data transfer and the receipt of such data.</p>
            <p>All processed personal information is stored on secure servers.</p>
            <p>All payment transactions are encrypted with corresponding keys and technologies.</p>
            <p>If we provided you with (or you chose a) password which gives you access to certain parts of our website or systems, you are responsible for the security and confidentiality of this password. We ask you to please not share this password with anyone.</p>
            <p>Unfortunately, data transfers via the Internet are not completely secure. Despite our best efforts to protect your personal data, we can’t guarantee the security of the data given to our website. Therefore, any data transfer is at your own risk.</p>
            <p>As soon as we receive your data we can apply strict procedures and security measures to prevent unauthorized access to it.</p>
            <p>You must understand that websites that are linked to from our website (or websites) and email correspondence from DueFocus may have a different Privacy Policy which differs from this Privacy Policy</p>
            <p>To provide protection of your confidentiality, we recommend that you read the confidentiality clause and privacy policies of these websites, applications, and other digital resources.</p>
          </div>
          <div className={styles.content}>
            <h2>Personal Data Security </h2>
            <p>We protect the data we have been trusted with and process them in accordance with this Policy. DueFocus takes physical, administrative, and technological steps to ensure the security of this personal data from unsanctioned access, usage, and distribution. In accordance with our agreements, we also demand that our vendors and partners protect this data from unsanctioned access, usage, and distribution. However, because we have no way of guaranteeing complete security of data in on the Internet, we can not provide you with a complete guarantee of the security of the data you share with us.</p>
          </div>
          <div className={styles.content}>
            <h2>Storage Of Personal Information </h2>
            <p>We store your personal data and use it in accordance to the goals with which this data was collected. We store and process your personal data to comply with business demands, lawful obligations, dispute settlement, protection of our assets, and implementation of agreements.</p>
          </div>
          <div className={styles.content}>
            <h2>Your Rights</h2>
            <p>When you provide your personal data for processing you have the following rights:</p>
            <ul>
              <li>You can demand access to the corresponding personal data (please see the point about gaining access to your personal data below);</li>
              <li>You can demand that we edit any incorrect personal data we have on you;</li>
              <li>Under certain conditions (for example, when the processing of your personal data is no longer necessary) you can demand that this data is deleted;</li>
              <li>In cases when we process your personal data based on your explicit consent for this processing, you can retract your agreement after which the processing will be terminated;</li>
              <li>In the case you have a complaint regarding the processing of your personal data, you can get in touch with us or, if you are in the EU, get in touch with your local representative.</li>
            </ul>
          </div>
          <div className={styles.content}>
            <h2>How To Retract Your Agreement Of The Processing Of Your Data? </h2>
            <p>You can retract your agreement on the processing of your personal information:</p>
            <ul>
              <li>By sending us an email to <span>privacy@diligences.com</span></li>
              <li>By mailing us by the address shown below</li>
            </ul>
          </div>
          <div className={styles.content}>
            <h2>How To Use The Right To Access Your Personal Data</h2>
            <p>You can use your right to access your personal data in the following ways:</p>
            <ul>
              <li>By emailing us at <span>privacy@diligences.com</span></li>
              <li>By mailing us by the address shown below</li>
              <p>Please note that to comply to all necessary security measures, before giving you access to information based on your request we may need to ask you to provide additional data to confirm your identity.</p>
              <p><b>Our contact details:</b> Diligences Inc, address: 297 Kingsbury Grade, Suite 100 Mail Box 4470, Stateline, NV89449-4470</p>
            </ul>
          </div>
          <div className={styles.content}>
            <h2>Changes To This Privacy Policy</h2>
            <p>All changes made to this Privacy Policy in the future will be published on this page and, if necessary, will be sent to you by email. Please visit this Privacy Policy page from time to time to avoid missing important updates to our Privacy Policy.</p>
          </div>
        </div>
      </>
    )
  }
}
